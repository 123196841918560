export type Target = "noone" | "nico" | "do" | "doCafe" | "top" | "do-cafe" | "one"

export const getTextColor = (target: Target) => {
  switch (target) {
    case "noone":
      return "text-noone"
    case "nico":
      return "text-nico"
    case "do":
      return "text-do"
    case "doCafe":
      return "text-doCafe"
    case "top":
      return "text-top"
    case "one":
      return "text-one"
  }
}

export const getBorderColor = (target: Target) => {
  switch (target) {
    case "noone":
      return "border-noone"
    case "nico":
      return "border-nico"
    case "do":
      return "border-do"
    case "doCafe":
      return "border-doCafe"
    case "top":
      return "border-top"
    case "one":
      return "border-one"
  }
}

export const getBgColor = (target: Target) => {
  switch (target) {
    case "noone":
      return "bg-noone"
    case "nico":
      return "bg-nico"
    case "do":
      return "bg-do"
    case "doCafe":
      return "bg-doCafe"
    case "top":
      return "bg-Brown"
    case "one":
      return "bg-one"
  }
}

export const getBgImage = (target: Target) => {
  switch (target) {
    case "noone":
      return "bg-nooneImage"
    case "nico":
      return "bg-nicoImage"
    case "do":
      return "bg-doImage"
    case "do-cafe":
    case "doCafe":
      return "bg-doCafeImage"
    case "top":
      return "bg-top"
    case "one":
      return "bg-oneImage"
  }
}

export const getBgWavePc = (target: Target) => {
  switch (target) {
    case "noone":
      return "md:bg-nooneWave"
    case "nico":
      return "md:bg-nicoWave"
    case "do":
      return "md:bg-doWave"
    case "doCafe":
      return "md:bg-doCafeWave"
    case "top":
      return "md:bg-topWave"
    case "one":
      return "md:bg-oneWave"
  }
}

export const getBgWaveSp = (target: Target) => {
  switch (target) {
    case "noone":
      return "bg-nooneWaveSp"
    case "nico":
      return "bg-nicoWaveSp"
    case "do":
      return "bg-doWaveSp"
    case "doCafe":
      return "bg-doCafeWaveSp"
    case "top":
      return "bg-topWaveSp"
    case "one":
      return "bg-oneWaveSp"
  }
}

export const getUnderlineWave = (target: Target) => {
  switch (target) {
    case "noone":
      return "md:bg-nooneUnderlineWave"
    case "nico":
      return "md:bg-nicoUnderlineWave"
    case "do":
      return "md:bg-doUnderlineWave"
    case "doCafe":
      return "md:bg-doCafeUnderlineWave"
    case "top":
      return "md:bg-topUnderlineWave"
    case "one":
      return "md:bg-oneUnderlineWave"
  }
}

export const getUnderlineWaveSp = (target: Target) => {
  switch (target) {
    case "noone":
      return "bg-nooneUnderlineWaveSp"
    case "nico":
      return "bg-nicoUnderlineWaveSp"
    case "do":
      return "bg-doUnderlineWaveSp"
    case "doCafe":
      return "bg-doCafeUnderlineWaveSp"
    case "top":
      return "bg-topUnderlineWaveSp"
    case "one":
      return "bg-oneUnderlineWaveSp"
  }
}
