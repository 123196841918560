/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { VFC } from "react"
import { useMemo } from "react"

type Props = {
  description?: string
  lang?: string
  meta?: Array<any>
  title?: string
  siteTitle?: string
}

const SEO: VFC<Props> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  siteTitle,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const titleTemplate = useMemo(() => {
    if (siteTitle) {
      return title ? `%s | ${siteTitle}` : siteTitle
    }
    return title ? `%s | ${defaultTitle}` : defaultTitle
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || siteTitle || defaultTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || siteTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title || siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
